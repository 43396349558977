$primary: #41696C;
$primary-50: #CAD5D6;
$secondary: #a74727;
$info: #92DEE0;
$info-50: #E0F6F6;
$danger: #d8534c;
$warning: #e7d94a;
$success: #23e059;
$success-700: #18983D;
$dark: #161616;
$gray: #ededed;
$transparent: transparent;
$white: white;
$black: black;
$light: #F5F5EF;

$gray-50: #FAFAFA;
$gray-100: #F9F9F9;
$gray-200: #F6F6F6;
$gray-300: #F3F3F3;
$gray-400: #F0F0F0;
$gray-500: #EDEDED;
$gray-600: #C7C7C7;
$gray-700: #A1A1A1;
$gray-800: #7B7B7B;
$gray-900: #555555;

$theme-colors: (
  'transparent': $transparent,

  'primary': #41696c,
  'primary-50': #CAD5D6,
  'primary-100': #BBC9CA,
  'primary-200': #9CB1B3,
  'primary-300': #7E999B,
  'primary-400': #5F8184,
  'primary-500': #41696c,
  'primary-600': #37585B,
  'primary-700': #2C4749,
  'primary-800': #223738,
  'primary-900': #172627,

  'secondary': #a74727,
  'secondary-50': #E6CBC3,
  'secondary-100': #DFBDB1,
  'secondary-200': #D19F8F,
  'secondary-300': #C3826C,
  'secondary-400': #B5644A,
  'secondary-500': #a74727,
  'secondary-600': #8C3C21,
  'secondary-700': #72301B,
  'secondary-800': #572514,
  'secondary-900': #3C1A0E,

  'info': #92DEE0,
  'info-50': #E0F6F6,
  'info-100': #D8F3F4,
  'info-200': #C6EEEF,
  'info-300': #B5E9EA,
  'info-400': #A3E3E5,
  'info-500': #92DEE0,
  'info-600': #7BBABC,
  'info-700': #639798,
  'info-800': #4C7374,
  'info-900': #355051,

  'success': #23e059,
  'success-50': #C1F6D1,
  'success-100': #B0F4C3,
  'success-200': #8DEFA9,
  'success-300': #69EA8E,
  'success-400': #46E574,
  'success-500': #23e059,
  'success-600': #1DBC4B,
  'success-700': #18983D,
  'success-800': #12742E,
  'success-900': #0D5120,

  'warning': #e7d94a,
  'warning-50': #F8F4CC,
  'warning-100': #F6F1BE,
  'warning-200': #F3EBA1,
  'warning-300': #EFE584,
  'warning-400': #EBDF67,
  'warning-500': #e7d94a,
  'warning-600': #C2B63E,
  'warning-700': #9D9432,
  'warning-800': #787126,
  'warning-900': #534E1B,

  'danger': #d8534c,
  'danger-50': #F4CFCD,
  'danger-100': #F1C1BF,
  'danger-200': #EBA6A2,
  'danger-300': #E48A85,
  'danger-400': #DE6F69,
  'danger-500': #d8534c,
  'danger-600': #B54640,
  'danger-700': #933834,
  'danger-800': #702B28,
  'danger-900': #4E1E1B,

  'gray': #ededed,
  'gray-50': #FAFAFA,
  'gray-100': #F9F9F9,
  'gray-200': #F6F6F6,
  'gray-300': #F3F3F3,
  'gray-400': #F0F0F0,
  'gray-500': #ededed,
  'gray-600': #C7C7C7,
  'gray-700': #A1A1A1,
  'gray-800': #7B7B7B,
  'gray-900': #555555,

  'light': #F5F5EF,
  'light-50': #FCFCFB,
  'light-100': #FBFBF9,
  'light-200': #FAFAF7,
  'light-300': #F8F8F4,
  'light-400': #F7F7F2,
  'light-500': #F5F5EF,
  'light-600': #CECEC9,
  'light-700': #A7A7A3,
  'light-800': #7F7F7C,
  'light-900': #585856,

  'dark': #161616,
  'dark-50': #BEBEBE,
  'dark-100': #ABABAB,
  'dark-200': #868686,
  'dark-300': #616161,
  'dark-400': #3B3B3B,
  'dark-500': #161616,
  'dark-600': #121212,
  'dark-700': #0F0F0F,
  'dark-800': #0B0B0B,
  'dark-900': #080808,

  'white': #ffffff,
  'white-50': #FFFFFF,
  'white-100': #FFFFFF,
  'white-200': #FFFFFF,
  'white-300': #FFFFFF,
  'white-400': #FFFFFF,
  'white-500': #ffffff,
  'white-600': #D6D6D6,
  'white-700': #ADADAD,
  'white-800': #858585,
  'white-900': #5C5C5C,

  'black': #000000,
  'black-50': #B8B8B8,
  'black-100': #A3A3A3,
  'black-200': #7A7A7A,
  'black-300': #525252,
  'black-400': #292929,
  'black-500': #000000,
  'black-600': #000000,
  'black-700': #000000,
  'black-800': #000000,
  'black-900': #000000,
);

// Function to generate CSS variables
@function generate-theme-colors($colors) {
  $output: ();

  @each $name, $color in $colors {
    $hover-color: lighten($color, 35%);
    $active-color: darken($color, 35%);
    
    $output: map-merge(
      $output, (
        "--bs-#{$name}": #{$color},
        "--bs-#{$name}-hover": #{$hover-color},
        "--bs-#{$name}-active": #{$active-color}
      )
    );
  }

  @return $output;
}

:root {
  @each $name, $value in generate-theme-colors($theme-colors) {
    #{$name}: #{$value};
  }
}

@each $name, $color in $theme-colors {
  .svg-#{$name} {
    path {
      fill: $color;
    }
    fill: $color;
  }
}


$table-striped-bg: $gray-100;

$btn-padding-y: 8px;
$btn-padding-x: 16px;

$body-color: $dark;
$dropdown-link-color: $dark;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;
$navbar-dark-hover-color: rgba($white, 0.9);
$grid-gutter-width: 32px;
$input-border-color: $dark;
$small-font-size: 12px;
$font-size-base: 0.8125rem;
$lead-font-size: 16px;
$h5-font-size: 16px;
$h4-font-size: 18px;
$h3-font-size: 24px;
$h2-font-size: 24px;
$h1-font-size: $font-size-base * 2.5;
$headings-line-height: 1.25;
$btn-border-radius: 24px;
$btn-border-radius-lg: 24px;
$btn-border-radius-sm: 24px;
$card-border-radius: 16px;
$input-border-radius: 0px;
$font-family-sans-serif: avenir;
$font-family-monospace: avenir;
$enable-responsive-font-sizes: true;
$enable-negative-margins: true;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $primary;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    8: (
      $spacer * 0.5,
    ),
    16: (
      $spacer,
    ),
    24: (
      $spacer * 1.5,
    ),
    32: (
      $spacer * 2,
    ),
    40: (
      $spacer * 2.5,
    ),
    48: (
      $spacer * 3,
    ),
    56: (
      $spacer * 3.5,
    ),
    64: (
      $spacer * 4,
    ),
    72: (
      $spacer * 4.5,
    ),
    80: (
      $spacer * 5,
    ),
    88: (
      $spacer * 5.5,
    ),
    96: (
      $spacer * 6,
    ),
    104: (
      $spacer * 6.5,
    ),
    112: (
      $spacer * 7,
    ),
    120: (
      $spacer * 7.5,
    ),
    128: (
      $spacer * 8,
    ),
    136: (
      $spacer * 8.5,
    ),
    144: (
      $spacer * 9,
    ),
    152: (
      $spacer * 9.5,
    ),
    168: (
      $spacer * 10.5,
    ),
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
  xxl: 1440px,
) !default;

$box-shadow-top-lg: 0 -1rem 3rem -1rem rgba($black, 0.175);
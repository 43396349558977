// Z-Index Utilities
$zIndexes: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10
);

@each $zIndex in $zIndexes {
  .z-index-#{$zIndex} {
    z-index: $zIndex;
  }
}

.z-index-n1 {
  z-index: -1;
}

.z-index-9999 {
  z-index: 9999;
  /* Ensures the div is above other content */
}

// Layout and Containers
.bill-summary-container {
  width: 100%;
}

@media only screen and (min-width: map-get($grid-breakpoints, lg)) {
  .bill-summary-container {
    width: 82%;
  }
}

.overflow-scroll-y {
  overflow-y: auto;
}

.circle-30 {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 1;
  border: 0px !important;
  margin-left: 1rem;
}

.circle-80 {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 1;
  border: 0px !important;
  margin-left: 1rem;
}

.btn-password-eye,
.btn-eye {
  border: none;
  background-color: transparent !important;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: transparent !important;
    color: $dark;
  }
}

.validation-form,
.not-form-control,
.form-control-border,
.password-form-control {
  color: $dark !important;
  border: none !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  transition: all 0.2s;

  &:focus-visible,
  &:hover {
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.2s;
  }
}

.password-form-control {
  border-bottom: 2px solid $gray !important;

  &:focus-visible,
  &:active,
  &:focus,
  &:hover {
    border-bottom: 2px solid $primary !important;
  }

  .form-control {
    border-bottom: 0px !important;

    &:focus-visible,
    &:active,
    &:focus,
    &:hover {
      border: none !important;
      border-radius: 5px 5px 0px 0px !important;
      transition: all 0.2s;
    }
  }
}

// Modals
.coming-soon-modal-header-image {
  background-image: url('../../../images/comingSoonHeader.svg');
  background-size: cover;
  color: $primary;
  height: 12vh;
  border-radius: 25px 25px 0px 0px;

  .btn-close {
    z-index: 999;
  }
}

.coming-soon-modal-header {
  color: $primary;
  height: 12vh;
  z-index: 999;
  border-radius: 25px 25px 0px 0px;
}

.coming-soon-modal-title {
  position: absolute;
  width: 100%;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.coming-soon-modal-body,
.custom-modal-body {
  border-radius: 0px 0px 25px 25px;
  background-color: white;
}

// Custom Tooltip
.custom-tooltip {
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 25px;
  width: 300px !important;
  max-width: 300px !important;

  .tooltip-inner {
    background-color: white !important;
    color: black !important;
    border-radius: 25px;
    max-width: 280px !important;
  }

  .tooltip-arrow {
    &:before {
      border-color: white !important;
    }
  }
}

.custom-tooltip.show {
  opacity: 1 !important;
}

// Backgrounds
.bg-404 {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.dashed-border {
  border-radius: 15px;
  border: 2px dashed $primary;
}

// Media Queries
@media only screen and (min-width: 992px) {
  .left-panel-border {
    border-right: 2px #AFC9CC solid;
  }
}

// Custom Searchbox
.custom-searchbox {
  border: none !important;
  background-color: $gray-100 !important;
  border-radius: 0 !important;

  &:hover,
  &:focus {
    background-color: $gray-200 !important;
    border: none !important;
    border-bottom: none !important;
    border-radius: none !important;
    transition: all 0.2s;
  }
}

// Custom Tabs
.nav-tabs.custom-tabs {
  .nav-link {
    border-bottom: none !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  .nav-link.active {
    background-color: $gray-50 !important;
  }
}

// Navigation
.nav_item {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.navBar-dropdown {
  position: fixed;
  top: 8vh !important;
  right: 16px !important;
  left: auto !important;
}

// Version Nav
.version-nav {
  position: fixed;
  top: 0px;
  left: 8px;
  padding: 8px;
  background-color: transparent;
  color: black;
  font-size: 12px;
  z-index: 9999;
  /* Ensures the div is above other content */
}

// Selectable
.selectable {
  cursor: pointer;
}

// Row Hover Effect
.row-hover-effect {
  border: 1px solid transparent !important;

  &:hover {
    border: 1px solid $info !important;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.05) !important;
    cursor: pointer;
  }
}

// Cursor
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-wait {
  cursor: wait !important;
}

// Searchable Dropdown
.searchable-clear-btn {
  top: 8px;
  right: 14px;

  svg {
    color: $dark !important;

    &:hover {
      color: $primary !important;
    }
  }
}

// Dropdown Alternate Shading
.dropdown-alternate-shading {
  &:nth-child(2n) {
    background-color: $gray-200 !important;
  }

  &:hover,
  &:active,
  &:focus {
    border: none !important;
    background-color: $gray-800 !important;
    font-weight: 500 !important;
  }
}

// Custom Bullet
.custom-bullet {
  ::marker {
    color: $primary;
  }
}

// Custom Shadow
.custom-shadow {
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05) !important;
}

// Custom Dropdown Toggle
.custom-navbar-dropdown {
  .dropdown-menu[data-bs-popper] {
    position: fixed;
    top: 86px !important;
    right: 10px !important;
    left: auto !important;
  }
}

.custom-dropdown-toggle::after {
  display: none !important;
}

// Custom Nav Dropdown
.custom-nav-dropdown {
  margin-top: 3vh !important;
  left: -10vw !important;
}

// Rounded Corners
.rounded-no-1 {
  border-top-left-radius: 0 !important;
  border-radius: 15px;
}

.rounded-no-2 {
  border-top-right-radius: 0 !important;
  border-radius: 15px;
}

.rounded-tab {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

// Form Select
.form-select {
  background-image: url('../../../images/formSelect.svg');
}

// Form Control Loadable
.form-control-loadable {
  color: $dark !important;
  border: none !important;
  border-bottom: 2px solid $primary !important;
  box-shadow: none !important;
  border-radius: 5px 5px 0px 0px !important;
  transition: all 0.2s;
  height: 32px;
  background-color: $primary !important;
}

// Text Loadable
.text-loadable {
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px 5px 5px 5px !important;
  transition: all 0.2s;
  background-color: $primary !important;
}

// Password Input Eye
.password-input-eye {
  padding: none !important;
  background: none;
  border: none;
}

.tabnav-active-underline {
  position: relative;
  &:after {
    content:'';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    background:$secondary;
    height:3px;
    border-radius: 3px;
  }
}

// table column popover
.table-popover-option {
  &:hover {
    background-color: $primary;
    color: white;
    .fa-check {
      color: white;
    }
  }
}

// QuickBooks Button
$default-quickbooks: '../../../images/connect-now-default.svg';
$hover-quickbooks: '../../../images/connect-now-hover.svg';

.custom-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;


  .button-image {
    background-image: url(#{$default-quickbooks});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    transition: background-image 0.3s ease;

    &:hover {
      background-image: url(#{$hover-quickbooks});
    }
  }
}
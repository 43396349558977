.rotating {
  animation-name: rotation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.slide {
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% { transform: translateY(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateY(0%); }
}

@keyframes slide-out {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
    opacity: 0;
  }
}

@import "./_vars";
// Bootstrap Overrides

// Typography
@font-face {
  font-family: 'Century Gothic';
  src: url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  src: url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap') format('truetype');
}

body {
  font-family: 'Century Gothic', sans-serif;
  font-size: 14px;
}

.base {
  font-size: 14px;
  font-family: "Avenir", sans-serif;
}

.h1, h1 {
  font-size: 40px;
  font-weight: 300;
  font-family: "Century Gothic", sans-serif;
}

.h2, h2 {
  font-size: 24px;
  font-weight: 400;
  font-family: "Century Gothic", sans-serif;
}

.h3, h3 {
  font-size: 18px;
  font-weight: 400;
  font-family: "Century Gothic", sans-serif;
}

.h4, h4 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Avenir", sans-serif;
}

.h5, h5 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Century Gothic", sans-serif;
}

lead, .lead {
  font-size: 16px;
  font-weight: 500;
  font-family: "Avenir", sans-serif;
}

.small {
  font-size: 12px;
  font-weight: 500;
  font-family: "Avenir", sans-serif;
}

.xsmall {
  font-size: 8px;
  font-weight: 400;
  font-family: "Avenir", sans-serif;
}

// Footer
footer {
  border-top: 1px solid $gray-500;
}

// Form
.form-control {
  color: $dark !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px 5px 0px 0px !important;
  transition: all 0.2s;
}

.form-control:not(select) {
  border-bottom: 2px solid $gray !important;
  &:focus-visible,
  &:hover {
    background-color: lighten($light, 3%) !important;
    // background-color: $light !important;
    border: none !important;
    border-bottom: 2px solid $primary !important;
    border-radius: 5px 5px 0px 0px !important;
    transition: all 0.2s;
  }
}

.form-check-label {
  padding: 2px !important;
  align-items: center;
}

.form-check-input:checked[type=radio] {
  background-image: none !important;
}

.form-check-input {
  margin-top: 0.15rem;
  border-radius: 50% !important;

  &:checked {
    background-color: $primary !important;
    border: $primary !important;
  }
}

.form-check-radio-custom .form-check-input {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>");
  background-position: left center;
  background-color: $dark !important;
  margin-top: auto;
  border-color: $dark !important;
  border-radius: 2em !important;
  width: 40px !important;
  height: 20px !important;

  &:checked {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>") !important;
    background-position: right center;
    background-color: $primary !important;
    border: $dark !important;
  }
}


// Components
.alert {
  z-index: 3 !important;
  color: $dark !important;
}

.card {
  border: none;
}

.btn-lg {
  @extend .rounded-pill;
  @extend .py-8;
}

.btn-transparent {
  &:hover,
  &.active {
    background-color: transparent !important;
  }
}

.nav-tabs,
.nav-link {
  font-size: 16px;
  color: $dark;
  font-weight: 600;
  border: none !important;

  &.active {
    color: $primary !important;
    border: none;
    font-weight: 800;

    path {
      fill: $primary !important;
    }
  }
}

a:hover {
  font-weight: 400 !important;
}

.status-pill {
  height: 28px;
}


// Offcanvas
.offcanvas {
  --bs-offcanvas-width: 725px;
  width: 596px !important;
}

// Animations
@keyframes fade-in {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    pointer-events: none;
  }

  100% {
    opacity: 0;
    pointer-events: none;
  }
}

@each $color-name, $color-value in $theme-colors {
  .notification-#{$color-name} {
    background-color: lighten($color-value, 30%) !important;
    color: darken($color-value, 30%) !important;
    border-color: $color-value !important;
  }
}